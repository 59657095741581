import React from "react"
import Layout from '../components/layout'

const ReceiptPage = () => {
  return (
    <Layout>

      <h2>Thank You</h2>
      <div className="columns">
        <div className="column is-one-half">
          <img src="/images/illustration.jpg" />
        </div>
        <div className="column">
          <p>
            At USSafetyEquipment.com we work hard to bring you the safety products you need with a quality your workers deserve. Our goal is to make it as quick and simple to order safety equipment online as it is to order from a local representative. Shop our online selection of the most common safety equipment needed for your job. We make it easy to find what you need with common sense categorization of products and tables listing pricing and options similar to the catalogs you are already accustomed to for ordering.
          </p>
        </div>
      </div>
    
      <p>
        We offer a large variety of the safety equipment that most contractors require. Browse a selection of their best selling products together and choose the equipment that suits your job requirements. Mix and match from both suppliers to get the safety equipment you need, something that other sites don't offer.
      </p>
      <p>
        We are confident you will find the safety equipment you need from our quality selection from top notch suppliers. We look forward to working with you to offer the safety your job deserves.
      </p>

      <p>US Safety Equipment is a subsidiary of National Tool Grinding, Inc in Erie, Pennsylvania. Our other brands include:</p>
      <ul>
        <li><h3>US Netting.com</h3></li>
        <li><h3>Birdguard.com</h3></li>
        <li><h3>Highway-Markers.com</h3></li>
      </ul>
    </Layout>
  )

}

export default ReceiptPage;